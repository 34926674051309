/* Input Section */
.task-input-container {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Multi-selection styling for Items and Persons */
  .scrollable-multi-column {
    display: grid;
    gap: 10px;
    max-height: 150px;
    overflow-y: auto;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    background-color: #f8f9fa;
  }
  
  .scrollable-multi-column.items {
    grid-template-columns: repeat(6, 1fr);
  }
  
  .scrollable-multi-column.persons {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .item {
    padding: 8px;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #ddd;
    transition: all 0.3s ease;
  }
  
  .item:hover {
    background-color: #e0f7fa;
    border-color: #00acc1;
  }
  
  .item.selected {
    background-color: #007bff;
    color: #fff;
    border-color: #0056b3;
  }
  
  /* Task Card Section */
  .task-card {
    position: relative;
    background-color: #ffffff;
    border: 2px solid #007bff;
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.3s ease;
    padding: 20px;
  }
  
  .task-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .task-card.done {
    opacity: 0.9;
    background-color: #f8f9fa;
    border: 2px solid #28a745;
  }
  
  /* Task Card Icons */
  .task-card-icons {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 10px;
  }
  
  .task-icon {
    font-size: 1.2rem;
    cursor: pointer;
    color: #777;
    transition: color 0.3s ease;
  }
  
  .task-icon:hover {
    color: #000;
  }
  
  .done-icon {
    color: #4caf50;
  }
  
  .delete-icon {
    color: #f44336;
  }
  
  /* Task Details */
  .task-card-title {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .task-card-body {
    margin-top: 10px;
  }
  
  .task-created-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    border-top: 1px solid #ddd;
    margin-top: 15px;
    font-size: 0.9rem;
    color: #555;
  }
  
  /* Styling for Items */
  .items-section {
    margin-top: 10px;
  }
  
  .task-item {
    display: inline-block;
    padding: 5px 10px;
    margin: 5px 5px 5px 0;
    border-radius: 5px;
    font-size: 0.9rem;
    font-weight: bold;
    border: 2px solid;
    transition: all 0.3s ease;
  }
  
  .task-item:nth-child(1) {
    border-color: #e74c3c;
    background-color: #ffebe8;
    color: #000;
  }
  
  .task-item:nth-child(2) {
    border-color: #3498db;
    background-color: #eaf6ff;
    color: #000;
  }
  
  .task-item:nth-child(3) {
    border-color: #2ecc71;
    background-color: #e9f7ef;
    color: #000;
  }
  
  .task-item:nth-child(4) {
    border-color: #f1c40f;
    background-color: #fef9e7;
    color: #000;
  }
  
  .task-item:nth-child(5) {
    border-color: #9b59b6;
    background-color: #f5eef8;
    color: #000;
  }
  
  .task-item:nth-child(6) {
    border-color: #e67e22;
    background-color: #fdebd0;
    color: #000;
  }
  
  .task-item.done {
    text-decoration: line-through;
    opacity: 0.5;
    color: #888;
    background-color: #f0f0f0;
    border-color: #ddd;
  }
  
  /* Styling for the DONE label */
  .done-label {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #28a745;
    color: #fff;
    font-size: 0.9rem;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 3px;
    z-index: 10;
    text-transform: uppercase;
  }
  
  /* DONE Banner */
  .done-banner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 150px;
    height: 40px;
    background-color: #28a745;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
    text-transform: uppercase;
    opacity: 0.8;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    z-index: 100;
    pointer-events: none;
  }
  
  /* Limit tasks to 4 per row */
  .task-list .row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .task-list .col {
    flex: 0 1 calc(25% - 20px);
  }
  
  /* Flight Number Styling */
  .flight-number {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  